html {
  font-family: Spectral, sans-serif;
  color: #000;
}

a, a:link, a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: #ffbb33;
  -webkit-text-decoration-color: #ffbb33;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Spectral;
}

#category {
  font-family: Spectral;
  font-weight: 500;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  margin: 20px;
  height: 800px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
  border-bottom: thin solid #000;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

/* Specific Styles */

.logo-text {
  font-size: 1.5rem;
  font-family: Spectral;
  font-weight: 800;
  font-style: italic;
  text-decoration: underline;
  text-decoration-color: #ffbb33;
  -webkit-text-decoration-color: #ffbb33;
}

.card-title {
  font-size: 1.25rem;
  font-family: Spectral;
  font-weight: 600;
}

.card-published-at {
  font-family: Spectral;
  font-style: italic;
  border-bottom: 1px dotted;
  margin: 0;
}

.card-read-full {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-read-full-gradient {
  height: 80px;
  background: linear-gradient(transparent 0%, rgba(255, 255, 255, 0.01) 1%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(transparent 0%, rgba(255, 255, 255, 0.01) 1%, rgba(255, 255, 255, 1) 100%);
  background: -moz-linear-gradient(transparent 0%, rgba(255, 255, 255, 0.01) 1%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(transparent 0%, rgba(255, 255, 255, 0.01) 1%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(transparent 0%, rgba(255, 255, 255, 0.01) 1%, rgba(255, 255, 255, 1) 100%);
}

.card-read-full-bottom {
  background-color: #fff;
}

.card-read-full-text {
  font-weight: 600;
  margin-bottom: 0;
}

.category-tag {
  display: inline-block;
  border-radius: 4px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffbb33;
}

.category-tag-text {
  margin: 0;
  color: #fff;
}

/* Spectral Font - Normal */

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-ExtraLight.ttf);
  font-weight: 200;
}

/* Spectral Font - Italics */

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Spectral';
  src: url(./fonts/Spectral/Spectral-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}
